<template>
  <TransitionRoot as="template" :show="props.isShow">
    <Dialog as="div" class="relative z-50" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-black bg-opacity-90 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0 overflow-y-auto"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative w-80 transform  text-sm rounded-lg bg-slate-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 "
            >
              <div class="h-[90vh] overflow-y-scroll">
                <p class="text-xl mb-5">Example ของ KBANK</p>

                <div class="space-y-5">
                  <div>
                    <p class="mb-1 text-lg">1.กด “สแกน” บนหน้าแรกของแอป K PLUS</p>
                    <img class="rounded-lg" src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/241f8e0b-61be-442a-3243-3d06310bc900/original" alt="">
                  </div>

                  <div>
                    <p class="mb-1 text-lg">2.เลือก QR รับเงิน และกดบันทึกภาพ ไปยังอัลบั้ม</p>
                    <img class="rounded-lg" src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/5817bdc0-10b1-446a-cfe2-50f92a058b00/original" alt="">
                  </div>
                </div>



                <div class="flex justify-end items-center">
                  <button
                    type="button"
                    @click="close"
                    class=" mt-4 py-2.5 px-5 mr-2 text-sm font-medium text-white focus:outline-none bg-red-700 shadow rounded-lg  "
                  >
                    <span>ปิดหน้าต่าง</span>
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
const emit = defineEmits(["closed"]);
const props = defineProps({
  isShow: { type: Boolean, default: false,},
});

const close = () => {
  emit("closed");
};

</script>
